
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































.experience__flexible {
  ::v-deep > div:first-child {
    margin-top: 0;
  }
}

.ctas {
  @include default-margin(0.5);
}
